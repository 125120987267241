import React from 'react';
import { createStore } from 'redux';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import registerServiceWorker from './registerServiceWorker';

import './index.css';

const store = createStore(() => {});
render(
  (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  ), document.getElementById('root'));

registerServiceWorker();
