    import React from "react";
    import ReactPlayer from "react-player";
    
    import Config from '../Config';

    import Preloader from './components/Preloader';
    import HeaderBar from './components/HeaderBar';

    const COMPONENT_NAME = 'Videos';

    class Videos extends React.Component {
      

      render = () => {
        return (
         
       <section id={`${COMPONENT_NAME}-View`} className="margin-tb">
   
            <HeaderBar
              id="VidHeader"
              classNames="row left-align black raised margin-tb-2 margin-lr-0"
              level={4}
             >
              Videos
            </HeaderBar>    
      

            <div classNames="pad-tb">
              <ReactPlayer id="You're Nowhere" COMPONENT_NAME="You're Nowhere"
                  width="340px"
                  url="youtu.be/Qmm2-3l6WIs"
              /> 
            </div>


            <div classNames="pad-tb">
              <ReactPlayer id="Without You" COMPONENT_NAME="Without You"
                width="340px"
                url="youtu.be/SE4cwG-9BYk"
              /> 
            </div>
             
            <div classNames="pad-tb">
              <ReactPlayer id="Waiting" COMPONENT_NAME="Waiting"
                width="340px"
                url="https://youtu.be/GfuWfiGRxoI"
              /> 
            </div>

            <div classNames="pad-tb">
            <ReactPlayer id="LongWay" COMPONENT_NAME="LongWay"
                width="340px"
                url="youtube.com/watch?v=hacxqIq79qI"
            /> 
            </div>

            <div className="pad-tb">
            <ReactPlayer id="whisper" COMPONENT_NAME="Whisper"
                width="340px"
                url="youtube.com/watch?v=mox8SRcutg0&feature=youtu.be"
            />               
            </div> 

            <div className="pad-tb">
            <ReactPlayer id="seeds" COMPONENT_NAME="seedsOfHope"
                width="340px"
                url="youtube.com/watch?v=MgVHabFUB-o&feature=youtu.be"
            />               
            </div> 

          <div className="pad-tb">
          <ReactPlayer id="Baddreams" COMPONENT_NAME="Baddreams"
              width="340px"
              url="youtube.com/watch?v=6ehH6HiHnf4&feature=youtu.be"
          />               
          </div>   
                      
          <div className="pad-tb">
          <ReactPlayer id="OntheHighway" COMPONENT_NAME="OnTheHighway"
              width="340px"
              url="youtube.com/watch?v=rYgiYFhBywc&feature=youtu.be"
          />               
          </div>          
              

        </section>       
        );
      };
    }

 export default Videos;
