import React from 'react';

import Anchor from './components/Anchor';
import config from '../Config/index.js';

// eslint-disable-next-line
const { APPNAME, COLORS, ASSETS } = config;
const { LIGHT_LOGO } = ASSETS;

const Footer = ({ style }) => (
  <section id="Footer" style={style}>
    <footer className={`${COLORS.second}`}>
        <div className="md-footer-left">
          {/* <!-- White Air Maypo Logo : Ya Mutha --> */}
          <img
            src={LIGHT_LOGO}
            alt="Maypo Deluxe Brand"
            className="md-logo-mape"
            style={{
              height: '48px',
            }}
          />
        </div>
      <div className="md-footer-center">
      <div className="md-container">
          

          <div className="md-footer-Container">
 
            <div className="footer-row">
            Contact us:
                <Anchor
                   id="InstagramSofia"
                   title="music@maypodeluxe.com"
                   href="mailto:music@maypodeluxe.com?Subject=Contact%20From%Website"
                   classNames="md-gold-text pad-l"
                   target="_Top"
                   styles={{ padding: '0', height: '50%' }}
               >
               </Anchor>
                
            </div>
            {/*

            <div className="footer-row">
                <Anchor
                  id="FooterSignup"
                  title=" Signup"
                  classNames="md-gold-text pad-r"
                  to="/logInOrSignUp"
                />
            for our Mailing List
           </div>
           */}
         
          <div className="footer-row">
           Art Direction:
               <Anchor
                   id="InstagramJohnny"
                   title="Johnny Scan"
                   href="https://www.instagram.com/scands3"
                   classNames="md-gold-text pad-l"
                   target="_blank"
                   styles={{ padding: '0', height: '50%' }}
               >
               </Anchor>
          </div>

          </div>
        </div>

          </div> 
        <div className="md-footer-right">

           <img
            src="https://storage.googleapis.com/maypo-deluxe-cdn/images/scuff_mutt_music.png"
            alt="ScuffMutt_Logo"
            style={{
              height: '55px',
                    
            }}
          />       
        </div>
        </footer>
  </section>
);

export default Footer;
