import React from 'react';

import Accordion from  './components/Accordion';
import Anchor from  './components/Anchor';
import HeaderBar from  './components/HeaderBar';
import Icon from  './components/Icon';

const items = [
  {
    header: false,
    child: (
      <p className="pad">
        {`
          Contact Us for questions regarding the following:
        `}
      </p>
    ),
  },
  {
    header: (
      <h5>
        <Icon icon="chevron_right" classNames="margin-r font-size-2" />
        Music - CDs, Digital Downloads, Streaming
      </h5>
    ),
    child: (
      <p className="pad">
        Email:
        <Anchor
          id="MailToSupport"
          href="mailto:music@maypodeluxe.com?subject=CDs and Digital Downloads"
          title="music@maypodeluxe.com"
          classNames="light-green-text pad-lr"
        />
      </p>
    ),
  },
  {
    header: (
      <h5>
        <Icon icon="chevron_right" classNames="margin-r font-size-2" />
        Booking Maypo Deluxe
      </h5>
    ),
    child: (
      <p className="pad">
        Email:
        <Anchor
          id="MailToSupport"
          href="mailto:booking@maypodeluxe.com?Subject=Booking the Band"
          title="booking@maypodeluxe.com"
          classNames="light-green-text pad-lr"
        />
      </p>
    ),
  },
  {
    header: (
      <h5>
        <Icon icon="chevron_right" classNames="margin-r font-size-2" />
        Producing and Recording guitar tracks
      </h5>
    ),
    child: (
      <p className="pad">
        Email:
        <Anchor
          id="MailToPromo"
          href="mailto:production@maypodeluxe.com?Subject=Producing and Guitar Work"
          title="production@maypodeluxe.com"
          classNames="light-green-text pad-lr"
        />
      </p>
    ),
  },

  // {
  //   child: (
  //     <Anchor
  //       id="ReadOurTerns"
  //       classNames="btn-primary btn-large black fluid"
  //       title="Terms & Conditions"
  //       to="terms"
  //     />
  //   )
  // }
]

const Contact = () => (
  <section id="ContactUsWrapper" className="margin-tb-2">
    <HeaderBar
      id="ContactUsHeader"
      level={4}
      classNames="row left-align black raised margin-0"
    >
      Contact Us
    </HeaderBar>
    <div className="margin-tb-2">
      <Accordion id="ContactUsContainer" items={items} />
    </div>
  </section>
);

export default Contact;
