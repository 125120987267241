const initializeSideNav = (e) => {
  if (global.$) {
    if (global.$('.button-collapse')) {
      setTimeout(() => {
        global.$('.button-collapse').sideNav('hide');
      }, 250);
    } else {
      setTimeout(() => {
        global.$('.button-collapse').sideNav();
      }, 250);
    }
  }
  if (e) {
    e.preventDefault();
  }
};


const hideMailChimp = (e) => {
  if (global.$) {
    if (global.$('#ToggleMailChimpSignUp')) {
      setTimeout(() => {
        global.$('#ToggleMailChimpSignUp').fadeOut();
      }, 250);
    } else {
      setTimeout(() => {
        global.$('#ToggleMailChimpSignUp').fadeOut();
      }, 250);
    }
  }
  if (e) {
    e.preventDefault();
  }
};


export default {
  NAV: {
    hideSideNav: initializeSideNav,
    hideMailChimp,
  },
};
