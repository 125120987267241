import React from 'react';

// NOT LINKED TO Mailchimp
const Promote = () => (
  false && <div
   id="PromoteFortekbeatzNow"
   className="ttyl-modal"
   style={{ display:'none' }}
 >
   <div id="BackgroundPromoteFortekbeatzNow" className="ttyl-modal-background"></div>
   <div className="ttyl-modal-content container pad-tb">
     <div className="margin-t margin-b-2 center-align">
       <button id="ClosePromoteFortekbeatzNowTop" className="btn btn-primary btn-large fluid">
         Close
       </button>
     </div>
     {/* <!--Start mc_embed_signup--> */}

       <div>Use Subscribe.jsp - not signing up users yet</div>
 
     {/* <!--End mc_embed_signup--> */}
 <div className="margin-t-2 center-align">
   <button
     id="ClosePromoteFortekbeatzNowBottom"
     className="btn btn-primary btn-large fluid"
   >
    Close
   </button>
  </div>
 </div>
   </div>
);

export default Promote;
