import React from 'react'
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import reactDOM from 'react-dom';
import { toast } from 'react-toastify';

import Anchor from './components/Anchor';

import "react-toastify/dist/ReactToastify.css";

toast.configure();

const Purchase = () => {
    
    const [product] = React.useState({
        name: "Bad Dreams",
        price: 11.99,
        description: "Maypo Deluxe CD",
    });
    
    async function handleToken(token) { 
        console.log("a:" + token);
        const response = await axios.post('https://us-central1-maypo-deluxe-app-265217.cloudfunctions.net/checkout', { token, product } );
        const { status } = response.data;
        if (status === "success") {
            toast("Success! Check email for details.", { type: "success"});
        } else {
            toast("Something went wrong", { type: "error"});
        }
    }
    
    return (
      <section id="Home"> 
            
        <div className="md-div-inline-wrapper">
        <div className="md-div-inline-purchase">            
        
            Availabe on CD or via download <br />

             <img
                id="BadDreams Cover"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/BadDreams_Cover_small.png" 
                alt="Bad Dreams Cover"
                height="300px"
              />
              <br />
            
              <StripeCheckout
                stripeKey="pk_live_GynXJe54sLGxbIgbg3qOgUmQ00R9dLY1Nu"
                token={handleToken}
                billingAddress
                shippingAddress
                amount={product.price * 100 }
                description={product.description}
                name={product.name}
                image="https://storage.googleapis.com/maypo-deluxe-cdn/images/white_md_brand.jpg"
                locale="auto"
                zipCode
                panelLabel="Purchase CD"

              />
                </div>
                    <div className="md-div-inline md-margin-top">
                        
                        <div className="md-deeplink">
                           Availabe now: 
                        </div>

                        <div className="md-deeplink">
                            <img src="https://storage.googleapis.com/maypo-deluxe-cdn/images/amazonmusic.png"  /> 
                            <Anchor
                              id="amazonMusicDeeplink"
                              title="AmazonMusic Deeplink"
                              href="https://music.amazon.com/albums/B07YHP7PSV?tab=CATALOG"
                              classNames="pad-l-1 waves-effect waves-light md-gold-text md-drop-shadow md-deeplink-Anchor"  
                            >                        
                            Amazon Music
                            </Anchor>
                        </div>                        
                        

                        <div className="md-deeplink">
                            <img src="https://storage.googleapis.com/maypo-deluxe-cdn/images/napster.png"  /> 
                            <Anchor
                              id="NapsterDeeplink"
                              title="Napster Deeplink"
                              href="https://us.napster.com/artist/maypo-deluxe/album/bad-dreams"
                              classNames="pad-l-1 waves-effect waves-light md-gold-text md-drop-shadow md-deeplink-Anchor"  
                            >                        
                            Napster
                            </Anchor>
                        </div>                         
                        

                        <div className="md-deeplink">
                            <img src="https://storage.googleapis.com/maypo-deluxe-cdn/images/spotify.png"  /> 
                            <Anchor
                              id="SporifyDeeplink"
                              title="Spotify Deeplink"
                              href="https://www.spotify.com/"
                              classNames="pad-l-1 waves-effect waves-light md-gold-text md-drop-shadow md-deeplink-Anchor"  
                            >                        
                            Spotify
                            </Anchor>
                        </div>
                        
                        <div className="md-deeplink">
                            
                            <img src="https://storage.googleapis.com/maypo-deluxe-cdn/images/iTunes_icon.png"  />
                            <Anchor 
                              id="iTunesDeeplink"
                              title="iTunes Deeplink"
                              href="https://www.apple.com/us/search/maypo-deluxe"
                              classNames="pad-l-1 waves-effect waves-light md-gold-text md-drop-shadow md-deeplink-Anchor"    
                            >
                            iTunes
                            </Anchor>    
                        </div>

                        <div className="md-deeplink">
                            <img src="https://storage.googleapis.com/maypo-deluxe-cdn/images/applemusic.png"  />
                            <Anchor
                              id="appleMusicDeeplink"
                              title="AppleMusic Deeplink"
                              href="https://www.apple.com/us/search/Maypo-Deluxe?src=serp"
                              classNames="pad-l-1 waves-effect waves-light md-gold-text md-drop-shadow md-deeplink-Anchor"
                            >
                            Apple Music
                            </Anchor>
                        </div>                        
                    
                        <div className="md-deeplink">
                            <img src="https://storage.googleapis.com/maypo-deluxe-cdn/images/pandora.png"  /> 
                            <Anchor
                              id="PandoraDeeplink"
                              title="Pandora Deeplink"
                              href="https://www.pandora.com/search/maypo%20deluxe/artists"
                              classNames="pad-l-1 waves-effect waves-light md-gold-text md-drop-shadow md-deeplink-Anchor"  
                            >                        
                            Pandora
                            </Anchor>
                        </div>
                        
                        {/* 
                        <div className="md-deeplink">
                            <img src="https://storage.googleapis.com/maypo-deluxe-cdn/images/iheartradio.png"  />
                            <Anchor
                              id="iHeartRadioDeeplink"
                              title="iHeartRadio Deeplink"
                              href="http://iHeartRadio.com"
                              classNames="pad-l-1 waves-effect waves-light md-gold-text md-drop-shadow md-deeplink-Anchor"
                            >
                            iHeart Radio
                            </Anchor>
                        </div>
                       */}

                           

                        
                    </div>
            </div>
        <br />
        <br />            
      </section> 
        

    )
    
}

export default Purchase;
