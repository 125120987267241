import React from 'react';

import Config from '../Config';

import HeaderBar from './components/HeaderBar';


const COMPONENT_NAME = 'Photos';

const { APPNAME } = Config;



const Photos = () => (

  
    
  <section id={`${COMPONENT_NAME}-View`} className="">
    <HeaderBar
      id="PhotoHeader"
      classNames="row left-align black raised margin-tb-2 margin-lr-0"
      level={4}
 
    >
      Photos
    </HeaderBar>
    <div className="white-text md-drop-shadow">
      
     <div className="md-photo-container">
         
        <span className="md-margin-left">Mark - Tampa,FL</span>         
        <div className="row md-photo">
              <img
                id="Mark in Tampa"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/mark_95.png"
                alt="Tampa"
                width="400px"
                align="left"
                hspace="1"
                vspace="8"
              />
         </div>

         <span className="md-margin-left">Marshall</span>         
        <div className="row md-photo">
              <img
                id="Marshall"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/gut_amp.jpg"
                alt="Scan"
                width="400px"
                align="left"
                hspace="1"
                vspace="8"
              />
         </div>

         <span className="md-margin-left">With a Strat</span>         
        <div className="row md-photo">
              <img
                id="Strat"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/markred_1.jpg"
                alt="Scan"
                width="400px"
                align="left"
                hspace="1"
                vspace="8"
              />
         </div>



         <span className="md-margin-left">Rob Torres (Robery Inc.)</span>
         <div className="row md-photo">
          <img
            id="Robb Torress"
            src="https://storage.googleapis.com/maypo-deluxe-cdn/images/RobbTorress.jpg"
            alt="Torress"
            width="400px"
            align="left"
            hspace="1"
            vspace="8"
          />
       
        </div>

         <span className="md-margin-left">Miguel Gonzalez</span>
         <div className="row md-photo">
          <img
            id="Miguel Gonzalez"
            src="https://storage.googleapis.com/maypo-deluxe-cdn/images/miguel.jpg"
            alt="Gonzalezr"
            width="400px"
            align="left"
            hspace="1"
            vspace="8"
          />
        </div>

         <span className="md-margin-left"> Mike Rudolph</span>
         <div className="row md-photo"> 
             <img
            id="Mike Rudolph"
            src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Pops.jpg"
            alt="Rudolph"
            width="400px"
            align="left"
            hspace="1"
            vspace="8"
          />

       
        </div>
         
         
        <span className="md-margin-left">Mark Scan, Tobacco Road</span>         
        <div className="row md-photo">
              <img
                id="Mark Scan"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/tobacco.jpg"
                alt="Scan"
                width="400px"
                align="left"
                hspace="1"
                vspace="8"
              />
         </div> 
         
         

       
        
         <span className="md-margin-left">Dan Bedea</span>
         <div className="row md-photo"> 
             <img
            id="Dan"
            src="https://storage.googleapis.com/maypo-deluxe-cdn/images/DanBedea.jpg"
            alt="Dan"
            width="400px"
            align="left"
            hspace="1"
            vspace="8"
          />

       
        </div>        

        <span className="md-margin-left">Large Crowd</span>
         <div className="row md-photo"> 
             <img
            id="allmancrowd"
            src="https://storage.googleapis.com/maypo-deluxe-cdn/images/crowd_gregg_allman.png"
            alt="Gregg Allman Crowd"
            width="400px"
            align="left"
            hspace="1"
            vspace="8"
          />

       
        </div>    
         
         <span className="md-margin-left">Mark at Crossroads</span>
         <div className="row md-photo"> 
             <img
            id="MScan Crossroads"
            src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Markie_live.png"
            alt="Mark CrossRoads"
            width="400px"
            align="left"
            hspace="1"
            vspace="8"
          />

       
        </div>        
         
         <span className="md-margin-left"> Cover shot for Homecoming</span>
         <div className="row md-photo"> 
             <img
            id="Homecoming cover"
            src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Popi_small.jpg"
            alt="Homecoming cover"
            width="400px"
            align="left"
            hspace="1"
            vspace="8"
          />

       
        </div>         
         
   
        </div>

        <span className="md-margin-left">Mark with Les Paul</span>         
        <div className="row md-photo">
              <img
                id="Mark Scan"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/pic_2.jpg"
                alt="Scan"
                width="400px"
                align="left"
                hspace="1"
                vspace="8"
              />
         </div>

        
    </div>
  </section>
);

export default Photos;
