import React, { Fragment } from 'react';

const Header = ({
  id,
  title,
  level,
  children,
  classNames,
  styles,
}) => (
  <Fragment>
    {!level === null}
    {level === 1 && (
      <h1
        id={id}
        title={title}
        className={classNames || 'pad-2 margin-0 fluid'}
        style={styles || {}}
      >
        {children || title}
      </h1>
    )}
    {level === 2 && (
      <h2
        id={id}
        title={title}
        className={classNames || 'pad-2 margin-0 fluid'}
        style={styles || {}}
      >
        {children || title}
      </h2>
    )}
    {level === 3 && (
      <h3
        id={id}
        title={title}
        className={classNames || 'pad-2 margin-0 fluid'}
        style={styles || {}}
      >
        {children || title}
      </h3>
    )}
    {level === 4 && (
      <h4
        id={id}
        title={title}
        className={classNames || 'pad-2 margin-0 fluid'}
        style={styles || {}}
      >
        {children || title}
      </h4>
    )}
    {level === 5 && (
      <h5
        id={id}
        title={title}
        className={classNames || 'pad-2 margin-0 fluid'}
        style={styles || {}}
      >
        {children || title}
      </h5>
    )}
    {level === 6 && (
      <h6
        id={id}
        title={title}
        className={classNames || 'pad-2 margin-0 fluid'}
        style={styles || {}}
      >
        {children || title}
      </h6>
    )}
  </Fragment>
);

export default Header;
