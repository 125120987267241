import React from 'react';

const Subscribe = () => (
  <div
   id="ToggleMailChimpSignUp"
   className="ttyl-modal"
   style={{ display:'none' }}
 >
   <div id="BackgroundCloseMailChimp" className="ttyl-modal-background"></div>
   <div className="ttyl-modal-content container pad-tb">
     <div className="margin-t-3 center-align">
       <button id="CloseMailChimpSignUp" className="btn btn-primary btn-large fluid">
         Close
       </button>
     </div>
       
     {/* <!--Start mc_embed_signup--> */}


       
    <div id="mc_embed_signup">
    <form action="https://gmail.us20.list-manage.com/subscribe/post?u=b7d06bf9661662ec94c271aaa&amp;id=518701b415" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate={true}>
        <div id="mc_embed_signup_scroll">
        <h2>Subscribe</h2>
    <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
    <div className="mc-field-group">
        <label for="mce-EMAIL">Email Address  <span className="asterisk">*</span>
    </label>
        <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" defaultValue="" />
    </div>
    <div className="mc-field-group">
        <label for="mce-FNAME">First Name </label>
        <input type="text" name="FNAME" className="" id="mce-FNAME" defaultValue="" />
    </div>
    <div className="mc-field-group">
        <label for="mce-LNAME">Last Name </label>
        <input type="text" name="LNAME" className="" id="mce-LNAME" defaultValue="" />
    </div>
    <div className="mc-field-group size1of2">
        <label for="mce-BIRTHDAY-month">Birthday </label>
        <div className="datefield">
            <span className="subfield monthfield"><input className="birthday " type="text" pattern="[0-9]*" placeholder="MM" size="2" maxlength="2" name="BIRTHDAY[month]" id="mce-BIRTHDAY-month" defaultValue="" /></span> / 
            <span className="subfield dayfield"><input className="birthday " type="text" pattern="[0-9]*"  placeholder="DD" size="2" maxlength="2" name="BIRTHDAY[day]" id="mce-BIRTHDAY-day" defaultValue="" /></span> 
            <span className="small-meta nowrap" >( mm / dd )</span>
        </div>
    </div>	<div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{display:'none'}}></div>
            <div className="response" id="mce-success-response" style={{display:'none'}}></div>
        </div> 
       {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_b7d06bf9661662ec94c271aaa_518701b415" tabindex="-1" value="" /></div>
        <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
        </div>
    </form>
</div>
       
       
  

     {/* <!--End mc_embed_signup--> */}
            
     <div className="center-align">
       <button
         id="CloseMailChimpSignUpBottom"
         className="btn btn-primary btn-large fluid"
       >
        Close
       </button>
     </div>
   </div>
 </div>
);

export default Subscribe;
