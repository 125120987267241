import React from 'react';
import Config from '../Config';
import Anchor from './components/Anchor';
import Preloader from './components/Preloader';
import HeaderBar from './components/HeaderBar';

const COMPONENT_NAME = 'Weedzie';

const { APPNAME } = Config;

const Weedzie = () => (
  <section id={`${COMPONENT_NAME}-View`} className="margin-tb">

    <HeaderBar
      id="BioHeader"
      classNames="row left-align black raised margin-tb-2 margin-lr-0"
      level={4}
    >
      Weedzie
    </HeaderBar>
   


    <div className="md-div-inline-wrapper">
      <div className="md-div-inline-gen">
          <div className="md-div-inline-gen">
              <img
                  id="Weedzie"
                  src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Weedzie_Cover.png"
                  alt="Weedzie Cover"
                  height="220px
                  "
                  align="left"
                  hspace="18"
                  vspace="8"
                  className="raised"  
                />



                

          </div>
          <div className="md-div-inline-gen margin-r-2">
            <p>
            Produced and Engineered by : Mark Scandariato<br/>                                                 
            All songs written by: Mark Scandariato except Angel written <br/>
            by Mark Scandariato and Miguel Gonzalez<br/>
            Released: 3.3.2021
            </p>
            <br/>
            Check out the
            <Anchor
                  id="LinkToStickMan"
                  title="LinkToStickMan"
                  url='https://stereostickman.com/music/maypo-deluxe-waiting/'
                  classNames="md-gold-text md-drop-shadow pad-lr"
                  target="_blank"
                >
              Waiting Review 
              </Anchor>
              on stereostickman.com 
            <br/>
            <img
                  id="ScuffMutt"
                  src="https://storage.googleapis.com/maypo-deluxe-cdn/images/scuff_mutt_music.png"
                  alt="ScuffMutt Music"
                  height="70px"
                  align="left"
                  hspace="40"
                  vspace="8"
                  className="raised"  
                />
          </div>
      </div>

      <div className="md-div-inline-gen">
          <div className="md-div-inline-gen">
              I want a CD!! <br/>
              <Anchor
                id="MailToGetWeedzie"
                href="mailto:music@maypodeluxe.com?subject=I want a Weedzie CD"
                title="music@maypodeluxe.com"
                classNames="md-gold-text md-drop-shadow"
              />
              
          </div>

          <div className="md-div-inline-gen margin-l-4">
            Use the email link provided above to purchase a CD. We'll walk you through the steps
            to fulfill your order.  Venmo and Zelle accepted.
            The price is $12.49 which includes shipping. Digital streaming for individual tracks is available using
            the links below.
          </div>
       </div>

      <div className="md-div-inline-gen">
          <div className="md-div-inline-gen">
            <ol>
              <li>Waiting</li>
              <li>Angel</li>
              <li>You're Nowhere</li>
              <li>Gone</li>
              <li>Without You</li>
              <li>Around Sun</li>
              <li>Part of Me</li>
              <li>Your Pain</li>
              <li>Distant Sun</li>
              <li>Who We Are</li>
              <li>Heading South</li>
            </ol>
          </div>

          <div className="md-div-inline-gen margin-l-4 margin-t-15">
              
          <Anchor
                id="LinkToSpotify"
                classNames="md-gold-text md-drop-shadow"
                title="LinkToSpotify"
                href='https://open.spotify.com/album/353E20hOQ2AiXXNEgyM4Ro'
             > 
            Spotify
          
          </Anchor>

            <span>&nbsp;&nbsp;</span>

          <Anchor
              id="LinkToiTunes"
              classNames="md-gold-text md-drop-shadow"
              title="LinkToitunes"
              href='http://itunes.apple.com/album/id1555401413?ls=1&app=itunes'
              target="_blank"
            > 
          iTunes
        
          </Anchor> 

            <span>&nbsp;&nbsp;</span>

            <Anchor
                id="LinkToAppleMusic"
                classNames="md-gold-text md-drop-shadow"
                title="LinkToAppleMusic"
                href='http://itunes.apple.com/album/id/1555401413'
                target="_blank"      
             > 
            Apple Music
          
            </Anchor>

            


          </div>
       </div>
      </div> 
      
    
  </section>
);

export default Weedzie;
