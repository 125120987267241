import React from 'react';
import Config from '../Config';
import Anchor from './components/Anchor';
import Preloader from './components/Preloader';
import HeaderBar from './components/HeaderBar';

const COMPONENT_NAME = 'Homecoming';

const { APPNAME } = Config;

const Homecoming = () => (
  <section id={`${COMPONENT_NAME}-View`} className="margin-tb">

    <HeaderBar
      id="BioHeader"
      classNames="row left-align black raised margin-tb-2 margin-lr-0"
      level={4}
    >
      Homecoming
    </HeaderBar>
    <div className="white-text md-drop-shadow">

     <div className="md-div-inline-wrapper"> 
      <div className="md-div-inline-gen">
        <div className="md-div-inline-gen">
            <img
                id="homecoming"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/HomeComing_Cover.png"
                alt="homecoming"
                height="220px
                "
                align="left"
                hspace="18"
                vspace="8"
                className="raised"  
              />
        </div>
        <div className="md-div-inline-gen pad-r">
          <p>Produced by: Kirk Kelsey</p>
          <p>Engineered by: Kirk Kelsey and Mark Scandariato</p>
          Released:    10/25/2014
          <br/>

          <img
                id="homecoming"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/scuff_mutt_music.png"
                alt="homecoming"
                height="90px"
                align="left"
                hspace="40"
                vspace="8"
                className="raised"  
              />

        </div>
      </div>
      <div className="md-div-inline-gen">
        <div className="md-div-inline-gen">
          <ol>
            <li>Home</li>
            <li>Long Way</li>
            <li>When She Loved Me</li>
            <li>Please Come to Lodi</li>
            <li>Away</li>
            <li>Wake Up With You</li>
            <li>It's OK</li>
            <li>Free</li>
            <li>Didn't We Do Alright</li>
            <li>On My Way</li>
          </ol>
        </div>
        <div className="md-div-inline-gen margin-l-4 margin-t-15">
            

        Available at: <br/>

        <Anchor
                id="LinkToiTunesFree"
                classNames="waves-effect waves-light md-blue md-drop-shadow md-deeplink-Anchor"
                title="LinkToiTunesFree"
                href='http://itunes.apple.com/album/id1516060306?ls=1&app=itunes'
  
             > 
            iTunes - FREE Single
          
            </Anchor>
            <br/>

            <Anchor
                id="LinkToSpotifyLongway"
                classNames="md-blue"
                title="LinkToSpotifyLongWay"
                href='https://open.spotify.com/album/6JGUElqoSWFSigsVK4Qa2x'
             > 
            Spotify - LONG WAY Single
          
            </Anchor>
            <br/>
            <Anchor
                id="LinkToSpotifyFree"
                classNames="waves-effect waves-light md-blue md-drop-shadow md-deeplink-Anchor"
                title="LinkToSpotifyFree"
                href='https://open.spotify.com/album/0feh8Aog6SAsGGm0i8XSkF'
             > 
            Spotify - FREE Single
          
            </Anchor>

            <br/>


            

        </div>
      </div>

    </div>


      
    </div>
  </section>
);

export default Homecoming;

