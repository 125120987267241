import React from 'react';

import Welcome from './Welcome';

import Anchor from './components/Anchor';
// eslint-disable-next-line
import Button from './components/Button';

// eslint-disable-next-line
import Routing from '../Routing';

import config from '../Config/index.js';

// eslint-disable-next-line
const { APPNAME, COLORS, ASSETS } = config;

const Home = ({ icon, auth }) => (
  <section id="Home">
    <Welcome />
        
    <div className="md-div-inline-wrapper">
          <div className="md-div-inline">
            
            {/* Weedzie */}
            <div className="md-div-inline-0">
              <div className="md-div-inline-1">
                    <Anchor
                        id="LinkToWeedzie"
                        title="LinkToWeedzie"
                        to='/Weedzie'
                      >
                      <img
                        id="Weedzie Cover"
                        src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Weedzie_Cover.png" 
                        alt="Weedzie Cover"
                        height="150px"
                      />
                    </Anchor>
           
              </div>
              <div className="md-div-inline-2">
                Title: Weedzie <br/>
                Produced by: Mark Scan <br/>
                Expected Rel: 03/05/2021
              </div>
            </div>



            <br/>

            
            {/* Bad Dreams */}
            <div className="md-div-inline-0">
              <div className="md-div-inline-1">

                    <Anchor
                        id="LinkToBadDreams"
                        title="LinkToBadDreams"
                        to='/Baddreams'
                      > 

                      <img
                        id="BadDreams Cover"
                        src="https://storage.googleapis.com/maypo-deluxe-cdn/images/BadDreams_Cover_small.png" 
                        alt="Bad Dreams Cover"
                        height="150px"
                      />
                    </Anchor>

                  <br/>
np
                  {/*
                
                      <Anchor
                        id="LinkToPurchase"
                        title="LinkToPurchase"
                        to='/Purchase'
                      >      
                          <Button
                              id="purchaseMD2cd"
                              type = "button"
                              onClick={'/Purchase'}
                              classNames={
                                `gold btn btn-primary fluid ${COLORS.third}-text waves-effect waves-light`
                              }
                              styles={{ height: '35px', width: '117px' }}
                              >
                              Get Music 
                          </Button> 
                          
                      </Anchor>
                */}

              </div>
              <div className="md-div-inline-2">
                Title: Bad Dreams <br/>
                Produced by: Mark Scan <br/>
                Released: 10/31/2019
              </div>
            </div>

            <br/> 

           {/* Home Coming */}
            <div className="md-div-inline-0">
              <div className="md-div-inline-1">

                      <Anchor
                        id="LinkToHomecoming"
                        title="LinkToHomecoming"
                        to='/Homecoming'
                      > 
                        <img
                          id="HomeComing Cover"
                          src="https://storage.googleapis.com/maypo-deluxe-cdn/images/HomeComing_Cover.png" 
                          alt="HomeComing Cover"
                          height="150px"
                        />

                       </Anchor> 
              </div>
              <div className="md-div-inline-2">
                Title: Homecoming <br/>
                Produced by: Kirk Kelsey <br/>
                Released: 10/25/2014
              </div>
            </div>






              
          </div>  

          <div className="md-div-inline hide-on-small-only">
            <img
                id="MaypoDeluxeLogo"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Maypo_Deluxe_logo_black.png"
                alt="ScuffMutt Music logo"
                height="180px"
                
              /> 
            </div>

      </div>   


  </section>
);

Home.displayName = 'Home';

export default Home;
