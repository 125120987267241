import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import FUNCTIONS from './Functions';

import Navigation from './Modules/Navigation';
import Home from './Modules/Home';
import Contact from './Modules/Contact';
import Footer from './Modules/Footer';
import NotFound from './Modules/NotFound';
import Bio from './Modules/Bio';
import Reviews from './Modules/Reviews';
import Photos from './Modules/Photos';
import Videos from './Modules/Videos';
import Links from './Modules/Links';
import Purchase from './Modules/Purchase';
import Confirm from './Modules/Confirm';
import Weedzie from './Modules/Weedzie';
import Baddreams from './Modules/Baddreams';
import Homecoming from './Modules/Homecoming';

import Preloader from './Modules/components/Preloader';
import Subscribe from './Modules/components/Mailchimp/Subscribe';
import Promote from './Modules/components/Mailchimp/Promote';

const { NAV } = FUNCTIONS;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { auth: false };
  }
  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      window.scrollTo(0, 0);
      NAV.hideSideNav();
      NAV.hideMailChimp();
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  render() {
    return (

      <Fragment>
        <Navigation
          history={{
            location: this.props.history.location,
            action: this.props.history.action,
          }}
          auth={this.state.auth}
        />
        <Subscribe />
        <Promote />
        <Preloader id="AppLoader" />
        <div
          id="root-body"
          className="container white-text"
          style={{ display: 'none'}}
        >
          {
            /**
            *  route to diffrent component
            */
          }
          <Switch>
            <Route
              exact
              path="/"
              component={Home}
              state={this.state}
            />
            <Route
              exact
              path="/home"
              component={Home}
              state={this.state}
            />
            <Route
              exact
              path="/contact"
              component={() => (
                <Contact auth={this.state.auth} />
              )}
              state={this.state}
            />

            <Route
              exact
              path="/bio"
              component={() => (
                <Bio auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/reviews"
              component={() => (
                <Reviews auth={this.state.auth} />
              )}
              state={this.state}
            />


            <Route
              exact
              path="/homecoming"
              component={() => (
                <Homecoming auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/baddreams"
              component={() => (
                <Baddreams auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/weedzie"
              component={() => (
                <Weedzie auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/photos"
              component={() => (
                <Photos auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/videos"
              component={() => (
                <Videos auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/links"
              component={() => (
                <Links auth={this.state.auth} />
              )}
              state={this.state}
            />

            <Route
              exact
              path="/confirm"
              component={() => (
                <Confirm auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/purchase"
              component={() => (
                <Purchase auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              component={() => (
                <NotFound auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Redirect to="home" />
          </Switch>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

App.propTypes = {
  location: PropTypes.shape({}),
  history: PropTypes.shape({
    listen: PropTypes.func,
    location: PropTypes.shape({}),
    action: PropTypes.string,
  }),
};

App.defaultProps = {
  location: {},
};

export default withRouter(App);
