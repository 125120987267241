import React from 'react';
import Config from '../Config';
import Anchor from './components/Anchor';
import Preloader from './components/Preloader';
import HeaderBar from './components/HeaderBar';

const COMPONENT_NAME = 'Bio';

const { APPNAME } = Config;

const Bio = () => (
  <section id={`${COMPONENT_NAME}-View`} className="margin-tb">

    <HeaderBar
      id="BioHeader"
      classNames="row left-align black raised margin-tb-2 margin-lr-0"
      level={4}
    >
      Biography
    </HeaderBar>
    <div className="white-text md-drop-shadow">
      
      <p>
      {/* <Anchor
          id="LinkToBuyCD"
          title=""
          to="/Purchase"
          classNames="pad-l-1"
          styles=
          {{ height: '100%' }}
        >  
              <img
                id="greggallmanshow"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/crowd_gregg_allman.png"
                alt="Gregg Allman Show"
                height="220px
                "
                align="left"
                hspace="18"
                vspace="8"
                className="raised"  
              />
          </Anchor>
                 */}

              <img
                id="greggallmanshow"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/crowd_gregg_allman.png"
                alt="Gregg Allman Show"
                height="220px
                "
                align="left"
                hspace="18"
                vspace="8"
                className="raised"  
              />
              <p>
              Call them a throwback to a simpler era, Maypo Deluxe is a band that mangages to craft their sound with instruments instead of samples, feel instead of steril perfection.
              </p>

              <p>
              While their songs have a retro appeal, they’re modern
 enough to catch the ear of any music lover. Bluesy vocal tracks that drive with intimacy and strength of melody that secure its hold on the listener increasingly throughout the musical journey.
              </p>

              <p>
              Great song-writing, proving all the more impressive and captivating as the lyrics and melody go on. 
              </p>

              The very sentiments of the opening track Waiting, such as this act of telling yourself you’ll be alright, reach out with more and more authority; ultimately connecting for their realness. And meanwhile, the soul-rock swagger of the instrumental continues to weave its web.
              </p>
              <p>
              Brilliantly crafted songs, an easy must for the year. 
              </p>


    </div>
  </section>
);

export default Bio;

