    import React from "react";
    import ReactPlayer from "react-player";
    
    import Config from '../Config';

    import Preloader from './components/Preloader';
    import HeaderBar from './components/HeaderBar';
    import Button from './components/Button';
    import Anchor from './components/Anchor';

    const COMPONENT_NAME = 'Links';

    class Links extends React.Component {
      

      render = () => {
        return (
         
       <section id={`${COMPONENT_NAME}-View`} className="margin-tb">
   
            <HeaderBar
              id="LinksHeader"
              classNames="row left-align black raised margin-tb-2 margin-lr-0"
              level={4}
             >
              Links
            </HeaderBar>    


        <Anchor
            id="LinkToSpotify"
            title="LinkToSpotify"
            type="button"
            url="https://open.spotify.com/album/353E20hOQ2AiXXNEgyM4Ro"
            classNames="btn-large md-btn-width pad-lr"
                    >
            Spotify - Waiting Single 
        </Anchor>
        <br/><br/>

        <Anchor
            id="LinkToiTunes"
            title="LinkToiTunes"
            type="button"
            url='http://itunes.apple.com/album/id1555401413?ls=1&app=itunes'
            classNames="btn-large md-btn-width pad-lr"
                    >
            iTunes 
        </Anchor>
        <br/><br/>

        <Anchor
            id="LinkToAppleMusic"
            title="LinkToAppleMusic"
            type="button"
            url="http://itunes.apple.com/album/id/1555401413"
            classNames="btn-large md-btn-width pad-lr"
                    >
            Apple Music
        </Anchor>
        <br/><br/>

        <Anchor
            id="LinkToInstagram"
            title="LinkToInstagram"
            type="button"
            url="https://www.instagram.com/maypodeluxe/"
            classNames="btn-large md-btn-width pad-lr"
                    >
            Instagram 
        </Anchor>
        <br/><br/>


        <Anchor
            id="LinkToYouTube"
            title="LinkToYouTube"
            type="button"
            url="https://www.youtube.com/channel/UCknnyuGGEjhN4_NGdBJ90yA"
            classNames="btn-large md-btn-width pad-lr"
                    >
            YouTube 
        </Anchor>
        <br/><br/>


        <Anchor
            id="LinkToTikTok"
            title="LinkToTikTok"
            type="button"
            url="https://www.tiktok.com/@maypodeluxe?"
            classNames="btn-large md-btn-width pad-lr"
                    >
            TikTok 
        </Anchor>

        <br/><br/>

        <Anchor
            id="linkToFacebook"
            title="LinkToFacebook"
            type="button"
            url="http://www.facebook.com/maypodeluxe"
            classNames="btn-large md-btn-width pad-lr"
                    >

            FaceBook
        </Anchor>




        </section>       
        );
      };
    }

 export default Links;
