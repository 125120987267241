import React from 'react';

import Config from '../Config';

import HeaderBar from './components/HeaderBar';
import Anchor from './components/Anchor';

const COMPONENT_NAME = 'Welcome';

// eslint-disable-next-line
const { APPNAME } = Config;

const Welcome = () => (
  <section id={`${COMPONENT_NAME}-View`} className="margin-tb">
    <HeaderBar
      id="WelcomeHeader"
      classNames="row left-align black raised margin-tb-2 margin-lr-0"
      level={4}
    >
      Weedzie is here!

      <br />
        
    </HeaderBar>
    <div classNames="white-text md-drop-shadow">
      
      <p>
      I want
      <Anchor
          id="LinkToWeedzie"
          title="LinkToWeedzie"
          to='/Weedzie'
          classNames="md-gold-text md-drop-shadow pad-lr"
        >
       Weedzie!! 
      </Anchor>

      Available on Spotify, iTunes, Amazon Music, and Pandora. 
      
      Check out our 
      <Anchor
          id="LinkToReviews"
          title="LinkToReviews"
          to='/Reviews'
          classNames="md-gold-text md-drop-shadow pad-lr"
        >
       Reviews 
      </Anchor>     


      </p>
    </div>
  </section>
);

export default Welcome;
