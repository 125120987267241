import React from 'react';

const Icon = ({ icon, side, classNames, styles }) => (
  <i
    className={
      `${classNames || ''} ${icon ? 'material-icons' : ''} ${side ? side : ''}`
    }
    style={styles}
  >
    { icon || '' }
  </i>
);

Icon.displayName = 'Icon';

export default Icon;
