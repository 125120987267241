const firebaseConfig = {
  apiKey: 'AIzaSyBCY07uUzUm0M2BwZuJ4c3Hh9C81fV9_2c',
  authDomain: 'maypo-deluxe-app-265217.firebaseapp.com',
  databaseURL: 'https://maypo-deluxe-app-265217.firebaseio.com',
  projectId: 'maypo-deluxe-app-265217',
  storageBucket: 'maypo-deluxe-app-265217.appspot.com',
  messagingSenderId: '851675835217',
  appId: '1:851675835217:web:ad6e33947751e241510170',
  measurementId: 'G-J2G2R58ZSL',
};

export default firebaseConfig;
