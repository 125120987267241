import React from 'react';

import Config from '../Config';

import HeaderBar from './components/HeaderBar';
import Anchor from './components/Anchor';

const COMPONENT_NAME = 'Reviews';

const { APPNAME } = Config;

const Reviews = () => (
    
  <section id={`${COMPONENT_NAME}-View`} className="">
    <HeaderBar
      id="PhotoHeader"
      classNames="row left-align black raised margin-tb-2 margin-lr-0"
      level={4}
    >
      Reviews
    </HeaderBar>

    <div className="white-text md-drop-shadow">
      
        <div className="md-photo-container">
            <h3>Waiting</h3> 
            <div className="row md-photo">
            <img
                id="Weedzie CD"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Weedzie_Cover.png"
                alt="Scan"
                width="90px"
                align="left"
                hspace="14"
                vspace="5"
              />    
            <p>
            Creative sound-design and organic Americana join forces for this shoulder-swaying rock groove and ambient hit of escapism from Maypo Deluxe.
            </p>
            <p>
            The very sentiments of Waiting, such as this act of telling yourself you’ll be alright, reach out with more and more authority; ultimately connecting for their realness. And meanwhile, the soul-rock swagger of the instrumental continues to weave its web.
            </p>
            <p>
            Brilliantly crafted, an easy must for the year. I look forward to hearing more from Maypo Deluxe.

            </p>

            <Anchor
                id="LinkToWaitingReview"
                title="Link To Waiting Review"
                href="https://stereostickman.com/music/maypo-deluxe-waiting/"
                classNames="md-gold-text pad-l"
                target="_blank"
            >
                Full Review
            </Anchor>    

            <Anchor
                id="LinkToSpotify"
                title="LinkToSpotify"
                href="https://open.spotify.com/album/353E20hOQ2AiXXNEgyM4Ro"
                classNames="md-gold-text pad-l-2"
                target="_blank"
             >
                Spotify Link
            </Anchor>
            
            <p>
              <br/>
              <b> We All Want Someone To Shout For</b> wrote:  ( Apr 2nd, 2021 )
              <br/>
             "Smooth classic rock influence with a tight knit folk rock delivery. Well made and confidently delivered".
            </p>

            </div>
        </div>    

        <div className="md-photo-container">
            <h3>You're Nowhere</h3>         
            <div className="row md-photo">
            <img
                id="Weedzie CD"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Weedzie_Cover.png"
                alt="Scan"
                width="90px"
                align="left"
                hspace="14"
                vspace="5"
              />    
            <p>
            Following the beautifully intoxicating introduction that was Waiting, Maypo Deluxe strikes with a another hit of beautifully intimate song-writing – and a hook section that explodes into life.
            </p>
            <p>
            Leading with a kind of soft-rock swagger through the mellow energy then rising anticipation of its verses, You’re Nowhere quickly progresses into indie rock anthem territory once the chorus drops in. Here the soundscape brightens, the vocal reaches new passionate realms, and the simplicity of the line embraces listeners in an immediately welcoming, soon addictive manner.
           </p>
            <p>
            It’s a fresh step away from Waiting, but it still fits the style, offers a powerful midde-8 and guitar solo, and lights up a new side to the artist’s creative arena. Well worth a listen at volume.
            </p>

            <Anchor
                id="LinkToYoureNoWhereReview"
                title="Link To YoureNowhere Review"
                href="https://stereostickman.com/music/maypo-deluxe-youre-nowhere/"
                classNames="md-gold-text pad-l"
                target="_blank"
            >
                Full Review
            </Anchor>    

            <Anchor
                id="LinkToSpotify"
                title="LinkToSpotify"
                href="https://open.spotify.com/album/06fw780VpMw0tcWstrVHtH"
                classNames="md-gold-text pad-l-2"
                target="_blank"
             >
                Spotify Link
            </Anchor>
            <br/><br/>



            </div>
        </div>  

        <div className="md-photo-container">
            <h3>Gone</h3>       
            <div className="row md-photo">
            <img
                id="Weedzie CD"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Weedzie_Cover.png"
                alt="Scan"
                width="90px"
                align="left"
                hspace="14"
                vspace="5"
              />    
            <p>
            Our redaction loves to discover unique vibes, artists who put the music forward while respecting it. Our discovery of the day goes by the name of Maypo Deluxe and he is on our list of the top 2021 creative artists.
            </p>
            <p>
            The artist proposes a pleasant musical approach, an outstanding recipe between rock, addictive energy, and retro hints. His latest single “Gone”, extracted from his album entitled “Weedzie”, immerses the audience in an authentic musical moment rocked by dynamic guitars and Bluesy vocals. A fresh artistic air into a crowded industry.
           </p>


            <Anchor
                id="LinkToGoneReview"
                title="Link To Gone Review"
                href="http://the-further.com/a-musical-journey-with-maypo-deluxe"
                classNames="md-gold-text pad-l"
                target="_blank"
            >
                Full Review
            </Anchor>    

            <Anchor
                id="LinkToSpotify"
                title="LinkToSpotify"
                href="https://open.spotify.com/album/5NvHnUppKEWjqnanJdKKlH"
                classNames="md-gold-text pad-l-2"
                target="_blank"
             >
                Spotify Link
            </Anchor>
            <br/><br/>



            </div>
        </div>         


        <div className="md-photo-container">
            <h3>Without You</h3>         
            <div className="row md-photo">
            <img
                id="Weedzie CD"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Weedzie_Cover.png"
                alt="Scan"
                width="90px"
                align="left"
                hspace="14"
                vspace="5"
              />    
            <p>
            Always the Maypo Deluxe sound appeals for its organic swagger and that crisp, natural presentation of nostalgic arrangements. Always the song-writing intrigues, too – and this is far from the exception. Maypo blends familiar building blocks with subtle details for additional poignancy, and weaves in a story that captivates for its authentic personal touch as well as its poetic relatability – ‘Remember when we were a team?’.
            </p>
            <p>
            Familiar yet fresh in the way that only Maypo Deluxe seems to master, Without You softly screams out on behalf of heartbreak and separation. The final third delves into the passionate uncertainty of this concept in a powerful fashion, the voice peaking, the guitar wandering into distortion and bends, the bass notes storming through with far more urgency. A brilliant progression that completely embraces its audience. 
            </p>
            <p>
            Gorgeous riffs and arpeggiation from two guitars, a wonderfully expressive vocal lead, a deeply moving display of vulnerability and honest desperation. Another beauty from Maypo Deluxe.
            </p>

            <Anchor
                id="LinkToWaitingReview"
                title="Link To Waiting Review"
                href="https://stereostickman.com/music/maypo-deluxe-without-you/"
                classNames="md-gold-text pad-l"
                target="_blank"
            >
                Full Review
            </Anchor>    

            <Anchor
                id="LinkToSpotify"
                title="LinkToSpotify"
                href="https://open.spotify.com/album/4SPCnxmQ2FsxR56CvrBg6w"
                classNames="md-gold-text pad-l-2"
                target="_blank"
             >
                Spotify Link
            </Anchor>
            <br/><br/>



            </div>
        </div>

    </div>

 
  </section>

);

export default Reviews;
