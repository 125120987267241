import React from 'react';


import Welcome from './Welcome';

import Anchor from './components/Anchor';
import Button from './components/Button';

import Routing from '../Routing';

import config from '../Config/index.js';

import Preloader from './components/Preloader';
import HeaderBar from './components/HeaderBar';

const { APPNAME, COLORS, ASSETS } = config;

const Confirm = () => (
  <section id="Confirm">

    <HeaderBar
      id="ConfirmHeader"
      classNames="row left-align black raised margin-tb-2 margin-lr-0"
      level={4}
    >
    Subscription Confirmed
    </HeaderBar>        
        
    <div className="md-div-inline-wrapper md-drop-shadow">
 
     Thank you for joining our Mailing List.
     <br />
     <br />
     We'll let you know about upcoming shows, events and new releases.
     Our New Album Bad Dreams is now Availalble.
     Click the Home button below to continue your journey.<br />
 
     <div className=" hide-on-small-only">    
          <img
        id="MaypoDeluxeLogo"
        src="https://storage.googleapis.com/maypo-deluxe-cdn/images/Maypo_Deluxe_logo_black.png"
        alt="Bad Dreams Cover"
        height="120px"
              /> 
        </div>

     <div className="">
         <Anchor
          id="LinkToHome"
          title="LinkToHome"
          to='/Home'
         >      
             <Button
                 id="purchaseMD2cd"
                 type = "button"
                 onClick={'/Home'}
                 classNames={
                   `gold btn btn-primary fluid ${COLORS.third}-text waves-effect waves-light`
                 }
                 styles={{ height: '35px', width: '117px' }}
                >
              Home    
             </Button> 
         </Anchor>        
        </div>

    </div> 
    <div className="md-empty-container">
        
    </div>    


  </section>
);



export default Confirm;
