import firebaseConfig from './firebaseConfig';

const sec = 'https://';
const domain = 'maypodeluxe.com';

const APP = {
  // APP Domains
  sec,
  domain,
  domainFull: (sec + domain),
};

const APPNAME = 'Maypo Deluxe';

const COLORS = {
  main: 'black',
  second: 'black',
  third: 'white',
  forth: '#ffc100',
};

const SOCIAL = {
  instagram: (
    'https://www.instagram.com/maypodeluxe/'
  ),
};

const storage = (
  'https://storage.googleapis.com/maypo-deluxe-cdn'
);

const assets = (
  storage + ''
);

const images = (
  assets + '/images'
);

const DARK_LOGO = (
  images + '/black_md_brand.jpg'
);

const MD_LOGO = (
  images + '/Maypo_Deluxe_logo.png'
);

const MD2_COVER = (
  images + '/BadDreams_Cover.png'
);

const LIGHT_LOGO = (
  images + '/white_md_brand.jpg'
);

const LOGO = (
  images + '/notused.jpg'
);

const BANNER = (
  images + '/blackwhite.jpg'
);

export default {
  APP,
  APPNAME,
  COLORS,
  FIREBASE_CONFIG: firebaseConfig,
  ASSETS: {
    LOGO,
    DARK_LOGO,
    LIGHT_LOGO,
    MD_LOGO,
    MD2_COVER,
    BANNER,
  },
  SOCIAL,
};


