import React from 'react';
import Config from '../Config';
import Anchor from './components/Anchor';
import Preloader from './components/Preloader';
import HeaderBar from './components/HeaderBar';

const COMPONENT_NAME = 'Baddreams';

const { APPNAME } = Config;

const Baddreams = () => (
  <section id={`${COMPONENT_NAME}-View`} className="margin-tb">

    <HeaderBar
      id="BioHeader"
      classNames="row left-align black raised margin-tb-2 margin-lr-0"
      level={4}
    >
      Bad Dreams
    </HeaderBar>
    <div className="white-text md-drop-shadow">

    <div className="md-div-inline-wrapper">

      <div className="md-div-inline-gen">
        <div className="md-div-inline-gen">
            <img
                id="Baddreams"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/BadDreams_Cover_small.png"
                alt="Baddreams Cover"
                height="220px
                "
                align="left"
                hspace="18"
                vspace="8"
                className="raised"  
              />
        </div>
        <div className="md-div-inline-gen">
          <p>Produced by: Mark Scandariato</p>
          <p>Engineered by: Mark Scandariato</p>
          Whisper - Produced and Engineered by Keith Rose<br/>
          Released: 10/31/2019
          <br/>

          <img
                id="ScuffMutt"
                src="https://storage.googleapis.com/maypo-deluxe-cdn/images/scuff_mutt_music.png"
                alt="ScuffMutt Music"
                height="90px"
                align="left"
                hspace="40"
                vspace="8"
                className="raised"  
              />

        </div>
      </div>
      <div className="md-div-inline-gen">
        <div className="md-div-inline-gen">
          <ol>
            <li>Bad Dreams</li>
            <li>Whisper</li>
            <li>All the Way</li>
            <li>This</li>
            <li>Seasons Change</li>
            <li>Seeds of Hope</li>
            <li>On the Highway</li>
            <li>So it Goes</li>
            <li>Hope You Don't Forget</li>
            <li>All Fall Down</li>
          </ol>
        </div>
        <div className="md-div-inline-gen margin-l-4 margin-t-15">
            Available at: <br/>
            <Anchor
                id="LinkToiTunes"
                title="LinkToiTunes"
                classNames="md-blue"
                href='http://itunes.apple.com/album/id1481692573?ls=1&app=itunes'
             > 
            iTunes
          </Anchor>
               <br/>
            <Anchor
                id="LinkToSpotify"
                classNames="md-blue"
                title="LinkToSpotify"
                href='https://open.spotify.com/album/3B085iDKE1S9uXwySfHCpy'
             > 
            Spotify
          
            </Anchor>
            <br/>
            <Anchor
                id="LinkToAmazon"
                classNames="waves-effect waves-light md-blue md-drop-shadow md-deeplink-Anchor"
                title="LinkToAmazon"
                href='https://music.amazon.com/albums/B07YHP7PSV?tab=CATALOG'
             > 
            Amazon Music
          
            </Anchor>
            


            

        </div>
      </div>
      </div>
    
    </div>
  </section>
);

export default Baddreams;

